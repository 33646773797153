import React, { useEffect, useState } from "react";
import HeaderContent from "../../../components/HeaderContent";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import InputComponent from "../../../components/FormComponent/Input";
import { Col, Form, Row, Spin, message, Input, Radio, Button } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import ButtonBase from "../../../components/Button";
import dayjs from "dayjs";
import {
  useCreateConceptMutation,
  useGetConceptByIdQuery,
  useUpdateConceptMutation,
} from "../../../store/Concepts";
import { LIST_ANALYSIS_TARGET } from "../../../constants";
import { useValidationRules } from "../../../constants/rules";
import withPermissionCheck from "../../../constants/PermissionHook";

const { TextArea } = Input;

const CreatEditConceptContainer = ({
  hasCreatePermission,
  hasEditPermission,
  hasDetailPermission,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams(); // Get id from URL
  const [form] = Form.useForm();
  const { InputTextRule } = useValidationRules();
  useEffect(() => {
    if (!hasCreatePermission || !hasDetailPermission) {
      navigate("/403");
    }
  }, [hasCreatePermission, navigate, hasDetailPermission]);

  const { data: detailConcept, isLoading: isLoadingDetail } =
    useGetConceptByIdQuery(id || "", {
      skip: !id,
      refetchOnMountOrArgChange: true,
    });
  const [textAreas, setTextAreas] = useState([{ key: dayjs().valueOf() }]);

  const handleAddTextArea = () => {
    setTextAreas([...textAreas, { key: dayjs().valueOf() }]);
  };
  const handleRemoveTextArea = (indexToRemove) => {
    let array = [...textAreas];
    const updatedTextAreas = array.filter(
      (_, index) => index !== indexToRemove
    );

    setTextAreas(updatedTextAreas);
  };
  const [createConcept] = useCreateConceptMutation();
  const [updateConcept] = useUpdateConceptMutation();

  useEffect(() => {
    if (detailConcept) {
      const listPrompts = detailConcept?.data?.prompts.map((prompt) => ({
        ...prompt,
        key: prompt.id,
      }));
      setTextAreas(listPrompts);
      form.setFieldsValue({
        name: detailConcept.data?.name,
        criteria: detailConcept.data?.criteria,
        type: detailConcept.data?.type,
        ...listPrompts.reduce(
          (acc, record, _) => ({
            ...acc,
            [`prompt-${record.id}`]: record.prompt,
            [`title-${record.id}`]: record.title,
          }),
          {}
        ),
      });
    } else {
      form.setFieldsValue({
        type: "organization",
      });
    }
  }, [detailConcept, form]);

  const onSubmitData = async (values) => {
    const output = [];

    Object.keys(values).forEach((key) => {
      const [type, id] = key.split("-"); // Tách key thành 2 phần: type (title hoặc prompt) và id
      // Nếu key là 'title', ta tìm 'prompt' có cùng id
      if (type === "title") {
        const title = values[key];
        const promptKey = `prompt-${id}`;
        const prompt = values[promptKey];

        if (prompt !== undefined) {
          // Gom cặp { title, prompt } vào một object và đẩy vào array output
          output.push({
            title,
            prompt: prompt,
          });
        }
      }
    });
    const payload = {
      name: values.name,
      criteria: values.criteria,
      type: values.type,
      prompts: output,
    };
    try {
      if (id) {
        await updateConcept({
          id: id,
          data: payload,
        }).unwrap();
        message.success(t("setting.createSuccess"));
      } else {
        await createConcept(payload).unwrap();
        message.success(t("setting.createSuccess"));
        form.resetFields();
      }
      navigate("/list-concepts");
    } catch (error) {
      if (error.status === 422) {
        return message.error(t("common.nameExists"));
      } else {
        message.error(t("setting.errorNetwork"));
      }
    }
  };

  return (
    <div>
      <div className="flex justify-between bg-white border-b border-[#e4e4e4]">
        <HeaderContent
          textCurrentPage={
            id ? t("concept.editConcept") : t("concept.createNewConcept")
          }
          textPreviousPage={t("account.backToList")}
          className="ml-3"
          actionBack={() => {
            navigate("/list-concepts");
          }}
        />
      </div>

      <div className="pt-12 flex justify-center">
        {isLoadingDetail ? (
          <Spin></Spin>
        ) : (
          <Form form={form} onFinish={onSubmitData}>
            <div className="bg-white w-[760px] h-full px-12 pt-12">
              <Row>
                <Col span={8}>
                  {t("setting.title")}
                  <span className="text-[#ff0000]">*</span>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t("setting.pleaseEnterField"),
                      },
                      InputTextRule,
                    ]}
                  >
                    <InputComponent />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  {t("concept.analysisTarget")}
                  <span className="text-[#ff0000]">*</span>
                </Col>
                <Col span={16}>
                  <Form.Item name="type">
                    <Radio.Group
                      options={LIST_ANALYSIS_TARGET}
                      className="hover:!border-[#ff7421]"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  {t("concept.scoringCriteria")}
                  <span className="text-[#ff0000]">*</span>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="criteria"
                    rules={[
                      {
                        required: true,
                        message: t("setting.pleaseEnterField"),
                      },
                    ]}
                  >
                    <TextArea
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      className="hover:!border-[#ff7421]"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {textAreas.map((textArea, index) => {
                return (
                  <div key={textArea.key}>
                    <Row>
                      {index === 0 ? (
                        <Col span={8}>
                          {t("concept.prompt")}
                          <span className="text-[#ff0000]">*</span>
                        </Col>
                      ) : (
                        <Col span={8}></Col>
                      )}
                      <Col span={16}>
                        <Form.Item
                          name={`title-${textArea.key}`}
                          rules={[InputTextRule]}
                        >
                          <InputComponent
                            defaultValue={textArea.prompt}
                            className="hover:!border-[#ff7421]"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}></Col>
                      <Col span={16} className="relative">
                        <Form.Item name={`prompt-${textArea.key}`}>
                          <TextArea
                            defaultValue={textArea.prompt}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            className="hover:!border-[#ff7421]"
                          />
                        </Form.Item>
                        <div className="absolute top-4 right-[-30px]">
                          <Button
                            icon={<MinusCircleOutlined />}
                            type="link"
                            danger
                            onClick={() => handleRemoveTextArea(index)}
                            disabled={textAreas.length === 1}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
              <Row>
                <Col span={8}></Col>
                <Col span={16} className="mt-[-15px]">
                  <Button type="text" onClick={handleAddTextArea}>
                    + <div className="ml-2">{t("setting.create")}</div>
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="flex justify-end mt-6">
              <ButtonBase
                className="w-[108px] h-[36px]"
                title={t("common.submit")}
                htmlType="submit"
                disabled={!hasEditPermission}
              />
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};
export default withPermissionCheck(CreatEditConceptContainer, {
  create: "create-concept",
  edit: "update-concept",
  detail: "detail-concept",
});
