import React, { useState } from "react";
import { Avatar, Dropdown, Modal } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { storeSetAuth } from "../../store/authReducer";
import ButtonBase from "../Button";
import { useLogoutMutation } from "../../store/Auth/Logout";
import { useTranslation } from "react-i18next";
import { apiSlices } from "../../store";

const HeaderBar = ({ logoConfig }) => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.auth.authInfo);
  const dispatch = useDispatch();
  const [isConfirmLogout, setIsConfirmLogout] = useState(false);
  const [logout, { isLoading }] = useLogoutMutation();

  const onHandleLogout = async () => {
    try {
      await logout();
      apiSlices.forEach((slice) => {
        dispatch(slice.util.resetApiState());
      });
      Cookies.remove("accessToken");
      localStorage.removeItem("activeMenuKey");
      dispatch(storeSetAuth(null));
      setIsConfirmLogout(false);
    } catch (error) {
      console.error("Logout failed: ", error);
    }
  };

  const menuItems = [
    {
      key: "logout",
      label: t("auth.logout"),
      icon: <LogoutOutlined />,
      onClick: () => setIsConfirmLogout(true),
    },
  ];
  return (
    <>
      <img src={logoConfig?.value} width={161} alt="logo" />
      <div className="flex items-center">
        <div className="mr-4 font-medium text-[13px]">
          <div className="mb-2 text-end">{userInfo?.name}</div>
          <div>{userInfo?.email}</div>
        </div>
        <div className="cursor-pointer">
          <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
            <Avatar size={40} icon={<UserOutlined />} />
          </Dropdown>
        </div>
      </div>
      <Modal
        open={isConfirmLogout}
        onCancel={() => setIsConfirmLogout(false)}
        footer={false}
      >
        <div className="text-center p-3">{t("auth.confirmLogout")}</div>
        <div className="text-center mt-4">
          <ButtonBase
            title={t("auth.cancel")}
            className="mr-3 !bg-white !text-[#333333]"
            onClick={() => setIsConfirmLogout(false)}
          />
          <ButtonBase
            title={t("auth.logout")}
            onClick={onHandleLogout}
            loading={isLoading}
          />
        </div>
      </Modal>
    </>
  );
};

export default HeaderBar;
