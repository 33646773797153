import dayjs from "dayjs";

export const countAgeToNow = (dateOfBirth) => {
  const today = dayjs();

  if (dateOfBirth) {
    const birthDate = dayjs(dateOfBirth);

    let age = today.year() - birthDate.year();

    if (today.isBefore(birthDate.add(age, "year"))) {
      age--;
    }
    return age;
  }
  return "";
};

export const handlePageChange = (pageChange, setParams) => {
  setParams((prev) => ({ ...prev, page: pageChange }));
};
export const handlePageSizeChange = (size, setParams) => {
  setParams((prev) => ({ ...prev, limit: size }));
};

export const calculateAverage = (stringArray) => {
  if (stringArray?.length > 0) {
    const total = stringArray.reduce((sum, str) => sum + Number(str), 0);

    const average = total / stringArray.length;

    return Number(average.toFixed(2));
  }
  return 0;
};
