import React, { useState } from "react";
import ButtonBase from "../../components/Button/index";
import TableBaseComponent from "../../components/FormComponent/Table";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import HeaderContent from "../../components/HeaderContent";
import { useNavigate } from "react-router-dom";
import {
  useDeleteCompanyMutation,
  useGetListCompanyQuery,
} from "../../store/Company";
import PopConfirmBase from "../../components/PopupConfirm";
import { handlePageChange, handlePageSizeChange } from "../../constants/ultil";
import withPermissionCheck from "../../constants/PermissionHook";

const ListCompanyContainer = ({
  hasCreatePermission,
  hasDetailPermission,
  hasDeletePermission,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
  });
  const { data: listCompany, isLoading } = useGetListCompanyQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const [deleteCompany] = useDeleteCompanyMutation();
  const onDeleteCompany = async (record) => {
    try {
      await deleteCompany(record.id).unwrap();
      message.success(t("setting.deleteSuccess"));
    } catch (error) {
      message.error(t("setting.errorNetwork"));
    }
  };
  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      width: 100,
    },
    {
      title: t("company.companyName"),
      key: "name",
      dataIndex: "name",
    },
    {
      title: t("company.address"),
      key: "address",
      dataIndex: "address",
    },
    {
      title: t("common.industry"),
      key: "industry",
      dataIndex: "industry",
      render: (_, record) => {
        return record?.industry?.name;
      },
    },
    {
      title: t("company.numberOfEmployee"),
      key: "number_of_employee",
      dataIndex: "number_of_employee",
      render: (_, record) => {
        return record?.number_of_employee;
      },
    },
    {
      title: t("setting.operation"),
      key: "action",
      render: (_, record) => (
        <div className="flex text-[#ff7421] cursor-pointer font-medium">
          <div
            className={`mr-5 text-[#ff7421] ${
              hasDetailPermission ? "" : "!text-[#a9a9a9] cursor-not-allowed"
            }`}
            onClick={() => {
              if (hasDetailPermission) {
                navigate(`/list-company/edit/${record.id}`);
              }
            }}
          >
            {t("setting.edit")}
          </div>
          {hasDeletePermission ? (
            <PopConfirmBase
              title={t("common.confirmDelete")}
              onConfirm={() => onDeleteCompany(record)}
            >
              <div className="font-medium text-[#ff7421] cursor-pointer">
                {t("setting.delete")}
              </div>
            </PopConfirmBase>
          ) : (
            <div className="font-medium text-[#a9a9a9] cursor-not-allowed">
              {t("setting.delete")}
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between bg-white border-b border-[#e4e4e4]">
        <HeaderContent
          textCurrentPage={t("common.listCompany")}
          className="ml-3"
        />
        <div className="flex items-center pr-5">
          <ButtonBase
            className="w-[108px] h-[36px] !bg-white !text-[#0B0A0A]"
            title={t("company.importCSV")}
            // onClick={() => navigate("/account/create")}
          />
          <ButtonBase
            className="w-[108px] h-[36px] ml-[10px]"
            title={t("account.newRegistration")}
            onClick={() => navigate("/list-company/create")}
            disabled={!hasCreatePermission}
          />
        </div>
      </div>
      <div className="p-4">
        <TableBaseComponent
          loading={isLoading}
          columns={columns}
          dataSource={listCompany?.data?.data}
          paginationOptions={{
            total: listCompany?.data?.total,
            defaultPageSize: params.limit,
            current: params?.page,
          }}
          handlePageChange={(pageChange) =>
            handlePageChange(pageChange, setParams)
          }
          handlePageSizeChange={(current, size) =>
            handlePageSizeChange(size, setParams)
          }
        />
      </div>
    </div>
  );
};

export default withPermissionCheck(ListCompanyContainer, {
  view: "list-companies",
  create: "create-company",
  detail: "detail-company",
  edit: "update-company",
  delete: "delete-company",
});
