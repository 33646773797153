import React, { useState, useEffect } from "react";
import HeaderContent from "../../../components/HeaderContent/index";
import TableBaseComponent from "../../../components/FormComponent/Table";
import { useTranslation } from "react-i18next";
import { useGetListPermissionQuery } from "../../../store/Settings/Permissions";
import { useNavigate } from "react-router-dom";
import {
  handlePageChange,
  handlePageSizeChange,
} from "../../../constants/ultil";
import { useGetUserInfoQuery } from "../../../store/Settings/Role";

const PermissionContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
  });
  const { data: userInfo } = useGetUserInfoQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  useEffect(() => {
    const hasAdmin = userInfo?.data?.role === "administrator";
    if (!hasAdmin) {
      navigate("/403");
    }
  }, [userInfo, navigate]);

  const { data: listPermissions } = useGetListPermissionQuery(undefined, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });

  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      width: 80,
    },
    {
      title: t("setting.title"),
      key: "name",
      dataIndex: "name",
      width: 300,
    },
    {
      title: t("setting.operation"),
      key: "action",
      render: (_, record) => (
        <div className="flex text-[#ff7421] cursor-pointer">
          <div
            className="mr-5"
            onClick={() => {
              localStorage.setItem("permissionId", record.id);
              navigate("/setting/permission/role");
            }}
          >
            {t("setting.detail")}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between bg-white border-b border-[#e4e4e4]">
        <HeaderContent
          textCurrentPage={t("setting.permission")}
          className="ml-3"
        />
      </div>
      <div className="p-4">
        <TableBaseComponent
          columns={columns}
          dataSource={listPermissions?.data?.data}
          paginationOptions={{
            total: listPermissions?.data?.total,
            defaultPageSize: params.limit,
            current: params?.page,
          }}
          handlePageChange={(pageChange) =>
            handlePageChange(pageChange, setParams)
          }
          handlePageSizeChange={(current, size) =>
            handlePageSizeChange(size, setParams)
          }
        />
      </div>
    </div>
  );
};
export default PermissionContainer;
