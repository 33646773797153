import SettingPosition from "../pages/Settings/Positions/index";
import SettingDepartment from "../pages/Settings/Department";
import SettingPermission from "../pages/Settings/Permission";
import SettingCompanyMissionVisionValue from "../pages/Settings/CompanyMissionVisionValue";
import SettingIndustry from "../pages/Settings/Industry";
import SettingCompanyHierarchy from "../pages/Settings/CompanyHierarchy";
import SettingJobTitle from "../pages/Settings/JobTitle";
import SettingEmploymentType from "../pages/Settings/EmploymentType";
import SettingRoles from "../pages/Settings/Permission/Role";
import AccountScreen from "../pages/Account";
import CreateEditAccountScreen from "../pages/Account/CreateEditAccount";
import ListCompanyScreen from "../pages/ListCompany";
import CreateEditCompanyScreen from "../pages/ListCompany/CreateEditCompany";
import ListConceptsScreen from "../pages/ListConcepts";
import CreatEditConceptContainer from "../containers/ListConcepts/CreateEditConcept";
import ImplementScreen from "../pages/Implement";
import HistoryScreen from "../pages/History";
import Page403 from "../pages/403";
import Page404 from "../pages/404";
import HomePage from "../pages/Home";
import SiteConfigScreen from "../pages/Settings/SiteConfig";

export const MENU_ROUTES = [
  {
    path: "/setting/position",
    component: <SettingPosition />,
  },
  {
    path: "/setting/department",
    component: <SettingDepartment />,
  },
  {
    path: "/setting/company_mission_vision_value",
    component: <SettingCompanyMissionVisionValue />,
  },
  {
    path: "/setting/industry",
    component: <SettingIndustry />,
  },
  {
    path: "/setting/company_hierarchy",
    component: <SettingCompanyHierarchy />,
  },
  {
    path: "/setting/job_title",
    component: <SettingJobTitle />,
  },
  {
    path: "/setting/employment_type",
    component: <SettingEmploymentType />,
  },
  {
    path: "/setting/permission",
    component: <SettingPermission />,
  },
  {
    path: "/setting/permission/role",
    component: <SettingRoles />,
  },
  {
    path: "/account",
    component: <AccountScreen />,
  },
  {
    path: "/account/create",
    component: <CreateEditAccountScreen />,
  },
  {
    path: "/account/edit/:id",
    component: <CreateEditAccountScreen />,
  },
  {
    path: "/list-company",
    component: <ListCompanyScreen />,
  },
  {
    path: "/list-company/create",
    component: <CreateEditCompanyScreen />,
  },
  {
    path: "/list-company/edit/:id",
    component: <CreateEditCompanyScreen />,
  },
  {
    path: "/list-concepts",
    component: <ListConceptsScreen />,
  },
  {
    path: "/list-concepts/create",
    component: <CreatEditConceptContainer />,
  },
  {
    path: "/list-concepts/edit/:id",
    component: <CreatEditConceptContainer />,
  },
  {
    path: "/implement",
    component: <ImplementScreen />,
  },
  {
    path: "/list-history",
    component: <HistoryScreen />,
  },
  {
    path: "/403",
    component: <Page403 />,
  },
  {
    path: "/404",
    component: <Page404 />,
  },
  {
    path: "/",
    component: <HomePage />,
  },
  {
    path: "/setting/site-config",
    component: <SiteConfigScreen />,
  },
];

export const LIST_ANALYSIS_TARGET = [
  { label: "組織", value: "organization" },
  { label: "階層", value: "hierarchy" },
  { label: "個人", value: "individual" },
];

export const DEFAULT_BIRTH = "1990/01/01";
export const ORGANIZATION = "organization";
export const HIERARCHY = "hierarchy";
export const INDIVIDUAL = "individual";

export const colorsChart = [
  "rgba(255, 99, 132, 0.6)",
  "rgba(54, 162, 235, 0.6)",
  "rgba(255, 206, 86, 0.6)",
  "rgba(75, 192, 192, 0.6)",
  "rgba(153, 102, 255, 0.6)",
  "rgba(255, 159, 64, 0.6)",
  "rgba(0, 255, 127, 0.6)",
  "rgba(144, 238, 144, 0.6)",
  "rgba(238, 130, 238, 0.6)",
  "rgba(255, 165, 0, 0.6)",
  "rgba(0, 128, 128, 0.6)",
];

export const borderColorsChart = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
  "rgba(0, 255, 127, 1)",
  "rgba(144, 238, 144, 1)",
  "rgba(238, 130, 238, 1)",
  "rgba(255, 165, 0, 1)",
  "rgba(0, 128, 128, 1)",
];
