import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from "js-cookie";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_DOMAIN,
  prepareHeaders: (headers, { getState, endpoint, type }) => {
    // Lấy token từ localStorage hoặc Redux state
    headers.set('Locale', 'ja')
    const token = Cookies.get('accessToken');
    const UPLOAD_ENDPOINTS = ['createEmployee', 'updateEmployee', 'uploadFile']
    if(!UPLOAD_ENDPOINTS.includes(endpoint)){
      headers.set('Content-Type', 'application/json');
    }
    
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    
    return headers;
  },
});

// Hàm baseQueryWithErrorHandling để xử lý lỗi
const baseQueryWithErrorHandling = async (args, api, extraOptions) => { 
  const result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    if (result.error.status === 401) {
      // Xử lý lỗi 401 (Unauthorized)
      console.error('Unauthorized! Redirecting to login...');
    } else if (result.error.status === 500) {
      // Xử lý lỗi 500 (Internal Server Error)
      console.error('Server Error! Please try again later.');
    } 
  }

  return result; // Trả về kết quả (thành công hoặc lỗi)
};

export default baseQueryWithErrorHandling;