import { Col, Form, Modal, Row, message } from "antd";
import React from "react";
import InputComponent from "../../../components/FormComponent/Input";
import "./index.scss";
import ButtonBase from "../../../components/Button";
import {
  useCreateJobTitleMutation,
  useUpdateJobTitleMutation,
} from "../../../store/Settings/JobTitle";
import { useTranslation } from "react-i18next";
import { useValidationRules } from "../../../constants/rules";

const CreateEditJobTitle = (props) => {
  const { t } = useTranslation();
  const { modalCreate, setModalCreate, handleSubmit, hasEditPermission } =
    props;
  const { InputTextRule } = useValidationRules();

  const [createJobTitle, { isLoading }] = useCreateJobTitleMutation();
  const [updateJobTitle, { isLoadingUpdate }] = useUpdateJobTitleMutation();

  const onSubmitJobTitle = async (values) => {
    try {
      if (modalCreate.data) {
        await updateJobTitle({
          id: modalCreate.data.id,
          data: values,
        }).unwrap();
        message.success(t("setting.updateSuccess"));
      } else {
        await createJobTitle(values).unwrap();
        message.success(t("setting.createSuccess"));
      }
      setModalCreate({ data: {}, isOpen: false });
    } catch (error) {
      message.error(t("setting.errorNetwork"));
    }
  };

  return (
    <Modal
      title={!modalCreate.data ? t("setting.create") : t("setting.edit")}
      open={modalCreate.isOpen}
      footer={false}
      onCancel={() => setModalCreate({ data: {}, isOpen: false })}
      onOk={handleSubmit}
    >
      <Form
        className="form-create-department"
        onFinish={onSubmitJobTitle}
        initialValues={modalCreate.data}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: t("setting.pleaseEnterField") },
                InputTextRule,
              ]}
            >
              <InputComponent title={t("setting.title")} required />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" rules={[InputTextRule]}>
              <InputComponent title={t("setting.detail")} />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex justify-center mt-4">
          <ButtonBase
            disabled={!hasEditPermission}
            loading={isLoading || isLoadingUpdate}
            title={t("common.submit")}
            htmlType="submit"
          />
        </div>
      </Form>
    </Modal>
  );
};

export default CreateEditJobTitle;
