import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import images from "../../assets/image-base";
import { useNavigate } from "react-router-dom";
import { SettingOutlined } from "@ant-design/icons";
import { useGetUserInfoQuery } from "../../store/Settings/Role";
const MenuBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeKey, setActiveKey] = useState(() => {
    return localStorage.getItem("activeMenuKey");
  });
  const { data: userInfo } = useGetUserInfoQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const menuItem = [
    {
      key: "1",
      icon: images.common.iconUser,
      iconActive: images.common.iconUserActive,
      label: t("common.accountList"),
      path: "/account",
      keyPermission: "list-users",
    },
    {
      key: "2",
      icon: images.common.iconListConcepts,
      iconActive: images.common.iconListConceptsActive,
      label: t("common.listOfConcept"),
      path: "/list-concepts",
      keyPermission: "list-concepts",
    },
    {
      key: "3",
      icon: images.common.iconListCompany,
      iconActive: images.common.iconListCompanyActive,
      label: t("common.listCompany"),
      path: "/list-company",
      keyPermission: "list-companies",
    },
    {
      key: "4",
      icon: images.common.iconImplement,
      iconActive: images.common.iconImplementActive,
      label: t("common.implementation"),
      path: "/implement",
      keyPermission: "create-report",
    },
    {
      key: "5",
      icon: images.common.iconHistory,
      iconActive: images.common.iconHistoryActive,
      label: t("common.history"),
      path: "/list-history",
      keyPermission: "list-reports",
    },
    {
      key: "6",
      icon: <SettingOutlined />,
      iconActive: <SettingOutlined />,
      label: t("common.masterData"),
      keyPermission: "administrator",
      children: [
        {
          key: "6.1",
          label: t("common.master"),
          path: "/setting/company_mission_vision_value",
          keyPermission: "list-company-mission-vision-values",
        },
        {
          key: "6.2",
          label: t("common.industry"),
          path: "/setting/industry",
          keyPermission: "list-industries",
        },
        {
          key: "6.3",
          label: t("common.hierarchy"),
          path: "/setting/company_hierarchy",
          keyPermission: "list-company-hierarchies",
        },
        {
          key: "6.4",
          label: t("employee.position"),
          path: "/setting/position",
          keyPermission: "list-positions",
        },
        {
          key: "6.5",
          label: t("common.department"),
          path: "/setting/department",
          keyPermission: "list-departments",
        },
        {
          key: "6.6",
          label: t("common.jobTitle"),
          path: "/setting/job_title",
          keyPermission: "list-job-titles",
        },
        {
          key: "6.7",
          label: t("common.employmentType"),
          path: "/setting/employment_type",
          keyPermission: "list-employment-types",
        },
        {
          key: "6.8",
          label: t("setting.permission"),
          path: "/setting/permission",
          keyPermission: "administrator",
        },
        {
          key: "6.9",
          label: t("common.siteConfig"),
          path: "/setting/site-config",
          keyPermission: "administrator",
        },
      ],
    },
  ];

  useEffect(() => {
    if (!activeKey) {
      navigate("/");
    } else if (activeKey?.startsWith("6")) {
      const menuActive = menuItem.find((item) => item.children);
      const subMenuActive = menuActive.children?.find((item) => {
        return item.key === activeKey;
      });

      if (subMenuActive) {
        navigate(subMenuActive.path);
      }
    } else {
      const menuActive = menuItem.find((item) => item.key === activeKey);

      if (menuActive) {
        navigate(menuActive.path);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (e) => {
    setActiveKey(e.key);
    localStorage.setItem("activeMenuKey", e.key);
  };

  return (
    <Menu
      onClick={handleClick}
      selectedKeys={[activeKey]}
      mode="inline"
      defaultOpenKeys={
        localStorage.getItem("activeMenuKey")?.startsWith("6") && ["6"]
      }
    >
      {menuItem.map((menu, index) => {
        if (
          !menu.children &&
          userInfo?.data?.list_permission.includes(menu.keyPermission)
        ) {
          return (
            <Menu.Item key={menu.key} onClick={() => navigate(menu.path)}>
              <div className="flex">
                {menu.icon && (
                  <img
                    src={activeKey === menu.key ? menu.iconActive : menu.icon}
                    alt={`menu_${index}`}
                  />
                )}
                <span className="ml-2 text-[13px]">{menu.label}</span>
              </div>
            </Menu.Item>
          );
        }
        if (menu.children && userInfo?.data?.role === "administrator") {
          return (
            <Menu.SubMenu
              key={menu.key}
              title={
                <div className="flex">
                  {menu.icon}
                  <span className="ml-2 text-[13px]">{menu.label}</span>
                </div>
              }
            >
              {menu.children.map((subMenu) => {
                if (
                  userInfo?.data?.list_permission.includes(
                    subMenu.keyPermission
                  ) ||
                  subMenu.keyPermission === "administrator"
                ) {
                  return (
                    <Menu.Item
                      key={subMenu.key}
                      onClick={() => navigate(subMenu.path)}
                    >
                      {subMenu.label}
                    </Menu.Item>
                  );
                }
                return <></>;
              })}
            </Menu.SubMenu>
          );
        }
        return <></>;
      })}
    </Menu>
  );
};

export default MenuBar;
