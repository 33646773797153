import React, { useEffect } from "react";
import { Bar, Line, Pie, Radar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import SelectComponent from "../FormComponent/Select";
import { borderColorsChart, colorsChart } from "../../constants";
import { Spin } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  RadialLinearScale,
  RadarController,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  RadialLinearScale,
  RadarController,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const listChart = [
  {
    value: "bar",
    label: "Bar Chart",
  },
  {
    value: "radar",
    label: "Radar Chart",
  },
];
const ChartsComponent = (props) => {
  const { t } = useTranslation();
  const {
    setDataChart,
    dataReport,
    infoReport,
    typeChart,
    setTypeChart,
    canvasRef,
    hiddenChart = false,
  } = props;

  useEffect(() => {
    if (infoReport) {
      if (typeChart === "radar") {
        const radarData = {
          labels: infoReport?.charts?.labels,
          datasets: [
            {
              label: infoReport?.charts?.datasets?.label,
              data: infoReport?.charts?.datasets?.data?.map(Number),
              backgroundColor: colorsChart[0],
              borderColor: borderColorsChart[0],
              borderWidth: 1,
              fill: true,
            },
          ],
        };

        setDataChart(radarData);
      } else {
        const radarData = {
          labels: infoReport?.charts?.labels,
          datasets: [
            {
              label: infoReport?.charts?.datasets?.label,
              data: infoReport?.charts?.datasets?.data?.map(Number),
              backgroundColor: colorsChart.slice(
                0,
                infoReport?.charts?.labels?.length
              ),
              borderColor: borderColorsChart.slice(
                0,
                infoReport?.charts?.labels?.length
              ),
              borderWidth: 1,
            },
          ],
        };
        setDataChart(radarData);
      }
    }
  }, [infoReport, typeChart, setDataChart]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      },
    },
  };
  const optionsRadar = {
    responsive: true,
    plugins: {
      legend: {
        display: true, // Hiển thị phần legend
      },
    },
    scales: {
      r: {
        beginAtZero: true,
        max: 100, // Cố định giá trị tối đa trục
      },
    },
  };
  return (
    <>
      {!hiddenChart && (
        <div className="flex justify-around my-4 text-xl font-bold">
          <div>{t("history.ScoringByItem")}</div>
          <SelectComponent
            className="w-[150px]"
            options={listChart}
            value={typeChart}
            onChange={(e) => {
              setTypeChart(e);
            }}
          />
        </div>
      )}

      <div
        className={`flex justify-center ${
          hiddenChart ? "invisible mt-[-700px]" : ""
        }`}
      >
        {dataReport.datasets && dataReport.datasets.length > 0 ? (
          <div className="w-3/4 ml-[-30px]">
            {typeChart === "bar" && (
              <Bar
                data={dataReport}
                options={options}
                className="!w-full"
                ref={canvasRef}
              />
            )}
            {typeChart === "line" && (
              <Line data={dataReport} options={options} />
            )}
            {typeChart === "pie" && <Pie data={dataReport} options={options} />}
            {typeChart === "radar" && (
              <Radar data={dataReport} options={optionsRadar} ref={canvasRef} />
            )}
          </div>
        ) : (
          <Spin></Spin>
        )}
      </div>
    </>
  );
};

export default ChartsComponent;
