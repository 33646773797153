import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { apiPositionSlice } from "./Settings/Positions";
import { apiDepartmentSlice } from "./Settings/Departments";
import { apiLoginSlice } from "./Auth/Login";
import { apiPermissionSlice } from "./Settings/Permissions";
import { apiRoleSlice } from "./Settings/Role";
import { apiAccountSlice } from "./Account";
import { apiForgotPasswordSlice } from "./Auth/ForgotPassword";
import { apiChangePasswordSlice } from "./Auth/ChangePassword";
import authReducer from "./authReducer";
import { apiLogoutSlice } from "./Auth/Logout";
import { apiEmployeeSlice } from "./Employee";
import { apiCompanySlice } from "./Company";
import { apiCompanyMissionVisionValueSlice } from "./Settings/CompanyMissionVisionValue";
import { apiIndustrySlice } from "./Settings/Industry";
import { apiCompanyHierarchySlice } from "./Settings/CompanyHierarchy";
import { apiJobTitleSlice } from "./Settings/JobTitle";
import { apiEmploymentTypeSlice } from "./Settings/EmploymentType";
import { apiConceptSlice } from "./Concepts";
import { apiReportSlice } from "./Implement";
import { apiSiteConfigSlice } from "./SiteConfig";

const persistConfig = {
  key: "root",
  storage,
};
export const apiSlices = [
  apiPositionSlice,
  apiDepartmentSlice,
  apiLoginSlice,
  apiPermissionSlice,
  apiRoleSlice,
  apiAccountSlice,
  apiForgotPasswordSlice,
  apiChangePasswordSlice,
  apiLogoutSlice,
  apiEmployeeSlice,
  apiCompanySlice,
  apiCompanyMissionVisionValueSlice,
  apiIndustrySlice,
  apiCompanyHierarchySlice,
  apiJobTitleSlice,
  apiEmploymentTypeSlice,
  apiConceptSlice,
  apiReportSlice,
  apiSiteConfigSlice,
]; // Mảng chứa tất cả các slices
const rootReducer = combineReducers({
  auth: authReducer,
  [apiPositionSlice.reducerPath]: apiPositionSlice.reducer,
  [apiDepartmentSlice.reducerPath]: apiDepartmentSlice.reducer,
  [apiLoginSlice.reducerPath]: apiLoginSlice.reducer,
  [apiPermissionSlice.reducerPath]: apiPermissionSlice.reducer,
  [apiRoleSlice.reducerPath]: apiRoleSlice.reducer,
  [apiAccountSlice.reducerPath]: apiAccountSlice.reducer,
  [apiForgotPasswordSlice.reducerPath]: apiForgotPasswordSlice.reducer,
  [apiChangePasswordSlice.reducerPath]: apiChangePasswordSlice.reducer,
  [apiLogoutSlice.reducerPath]: apiLogoutSlice.reducer,
  [apiEmployeeSlice.reducerPath]: apiEmployeeSlice.reducer,
  [apiCompanySlice.reducerPath]: apiCompanySlice.reducer,
  [apiCompanyMissionVisionValueSlice.reducerPath]:
    apiCompanyMissionVisionValueSlice.reducer,
  [apiIndustrySlice.reducerPath]: apiIndustrySlice.reducer,
  [apiCompanyHierarchySlice.reducerPath]: apiCompanyHierarchySlice.reducer,
  [apiJobTitleSlice.reducerPath]: apiJobTitleSlice.reducer,
  [apiEmploymentTypeSlice.reducerPath]: apiEmploymentTypeSlice.reducer,
  [apiConceptSlice.reducerPath]: apiConceptSlice.reducer,
  [apiReportSlice.reducerPath]: apiReportSlice.reducer,
  [apiSiteConfigSlice.reducerPath]: apiSiteConfigSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Tạo store với reducer đã được persist
export const store = configureStore({
  reducer: persistedReducer, // Truyền persistedReducer vào store
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(...apiSlices.map((slice) => slice.middleware)),
});

// Tạo persistor để lưu trữ state
export const persistor = persistStore(store);
