import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Spin } from "antd";
import ButtonBase from "../../../components/Button";
import ListEmployeesContainer from "../ListEmployee";
import FormCreateEditCompany from "./FormCreateEdit";
import { useGetCompanyByIdQuery } from "../../../store/Company";
import HeaderContent from "../../../components/HeaderContent";
import { useGetUserInfoQuery } from "../../../store/Settings/Role";

const IS_CREATE_COMPANY = 1;
const IS_LIST_CANDIDATE = 2;
const onChangeColor = (key, buttonKey) => {
  if (key === buttonKey) {
    return "bg-[#FF7421] text-white";
  } else {
    return "!bg-white !text-[#0B0A0A]";
  }
};

const CreatEditCompanyContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams(); // Get id from URL
  const { data: userInfo } = useGetUserInfoQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  useEffect(() => {
    const hasCreatePermission =
      userInfo?.data?.list_permission.includes("create-company");
    const hasDetailPermission =
      userInfo?.data?.list_permission.includes("detail-company");
    if (!hasCreatePermission || !hasDetailPermission) {
      navigate("/403");
    }
  }, [userInfo, navigate]);

  const hasPermissionCandidate =
    userInfo?.data?.list_permission.includes("list-persons");
  const hasEditPermission =
    userInfo?.data?.list_permission.includes("update-company");
  const { data: detailCompany, isLoading: isLoadingDetail } =
    useGetCompanyByIdQuery(id || "", {
      skip: !id,
      refetchOnMountOrArgChange: true,
    });

  const [activeKey, setActiveKey] = useState(IS_CREATE_COMPANY);

  const buttonColor1 = useMemo(
    () => onChangeColor(activeKey, IS_CREATE_COMPANY),
    [activeKey]
  );
  const buttonColor2 = useMemo(
    () => onChangeColor(activeKey, IS_LIST_CANDIDATE),
    [activeKey]
  );

  return (
    <div>
      <div className="flex justify-between bg-white border-b border-[#e4e4e4]">
        <HeaderContent
          textCurrentPage={
            id ? t("company.editCompany") : t("company.createNewCompany")
          }
          textPreviousPage={t("account.backToList")}
          className="ml-3"
          actionBack={() => {
            navigate("/list-company");
          }}
        />
      </div>

      <div className="pt-4">
        {isLoadingDetail ? (
          <div className="flex justify-center mb-4">
            <Spin></Spin>
          </div>
        ) : (
          <div>
            {id && (
              <div className="flex justify-center mb-4">
                <ButtonBase
                  className={`w-[172px] h-[46px] mr-[10px] ${buttonColor1}`}
                  title={t("company.createCompanyInfo")}
                  onClick={() => setActiveKey(IS_CREATE_COMPANY)}
                />
                <ButtonBase
                  className={`w-[172px] h-[46px] ${buttonColor2}`}
                  title={t("company.createEmployeeInfo")}
                  onClick={() => setActiveKey(IS_LIST_CANDIDATE)}
                  disabled={!hasPermissionCandidate}
                />
              </div>
            )}
            {activeKey === IS_CREATE_COMPANY && (
              <FormCreateEditCompany
                detailCompany={detailCompany}
                idCompany={id}
                hasEditPermission={hasEditPermission}
              />
            )}
            {activeKey === IS_LIST_CANDIDATE && (
              <ListEmployeesContainer idCompany={id} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default CreatEditCompanyContainer;
