import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TableBaseComponent from "../../components/FormComponent/Table";
import HeaderContent from "../../components/HeaderContent";
import ModalDetailReport from "./ModalDetailReport";
import FilterHistoryContainer from "./Filter";
import {
  useDeleteHistoryReportMutation,
  useGetListReportsQuery,
} from "../../store/Implement";
import { handlePageChange, handlePageSizeChange } from "../../constants/ultil";
import PopConfirmBase from "../../components/PopupConfirm";
import dayjs from "dayjs";
import { useGetListJobTitleQuery } from "../../store/Settings/JobTitle";
import { useGetListPositionsQuery } from "../../store/Settings/Positions";
import { message } from "antd";
import withPermissionCheck from "../../constants/PermissionHook";

const ListHistoryContainer = ({ hasDetailPermission, hasDeletePermission }) => {
  const { t } = useTranslation();
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
  });
  const [paramsQuery] = useState({
    limit: 1000,
    page: 1,
  });
  const [modalDetail, setModalDetail] = useState({
    data: [],
    isOpen: false,
  });
  const { data: listReportHistory, isLoading } = useGetListReportsQuery(
    params,
    {
      refetchOnMountOrArgChange: true, // auto call API when component mount
    }
  );
  const { data: listJobTitle } = useGetListJobTitleQuery(paramsQuery, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });

  const { data: listPositions } = useGetListPositionsQuery(paramsQuery, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const [deleteHistoryReport] = useDeleteHistoryReportMutation();

  const onDeleteHistoryReport = async (record) => {
    try {
      await deleteHistoryReport(record.id).unwrap();
      message.success(t("setting.deleteSuccess"));
    } catch (error) {
      message.error(t("setting.errorNetwork"));
    }
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      width: 100,
    },
    {
      title: t("history.staffName"),
      key: "name",
      dataIndex: "name",
      render: (_, record) => {
        return record?.persons?.[0]?.name;
      },
    },
    {
      title: t("history.concept"),
      key: "concept",
      dataIndex: "concept",
      render: (_, record) => {
        return record?.concept?.name;
      },
    },
    {
      title: t("company.companyName"),
      key: "companyName",
      dataIndex: "companyName",
      render: (_, record) => {
        return record?.company?.name;
      },
    },
    {
      title: t("common.jobTitle"),
      key: "jobTitle",
      dataIndex: "jobTitle",
      render: (_, record) => {
        return listJobTitle?.data?.data?.find(
          (item) => item.id === record?.job_title_id
        ).name;
      },
    },
    {
      title: t("history.implementDate"),
      key: "implementDate",
      dataIndex: "implementDate",
      render: (_, record) => {
        return dayjs(record?.created_at).format("DD/MM/YYYY");
      },
    },
    {
      title: t("employee.position"),
      key: "position",
      dataIndex: "position",
      render: (_, record) => {
        return listPositions?.data?.data?.find(
          (item) => item.id === record?.position_id
        ).name;
      },
    },

    {
      title: t("setting.operation"),
      key: "action",
      render: (_, record) => (
        <div className="flex text-[#ff7421] cursor-pointer font-medium">
          <div
            className={`mr-5 text-[#ff7421] ${
              hasDetailPermission ? "" : "!text-[#a9a9a9] cursor-not-allowed"
            }`}
            onClick={() => {
              if (hasDetailPermission) {
                setModalDetail({ data: record, isOpen: true });
              }
            }}
          >
            {t("history.resultDetail")}
          </div>
          {hasDeletePermission ? (
            <PopConfirmBase
              title={t("common.confirmDelete")}
              onConfirm={() => onDeleteHistoryReport(record)}
            >
              <div className="font-medium text-[#ff7421] cursor-pointer">
                {t("setting.delete")}
              </div>
            </PopConfirmBase>
          ) : (
            <div className="font-medium text-[#a9a9a9] cursor-not-allowed">
              {t("setting.delete")}
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between bg-white border-b border-[#e4e4e4]">
        <HeaderContent
          textCurrentPage={t("history.searchHistory")}
          className="ml-3"
        />
      </div>
      <FilterHistoryContainer
        listJobTitle={listJobTitle?.data?.data}
        listPositions={listPositions?.data?.data}
        paramsSearch={params}
        setParamsSearch={setParams}
      />
      <div className="p-4">
        <TableBaseComponent
          loading={isLoading}
          columns={columns}
          dataSource={listReportHistory?.data?.data}
          paginationOptions={{
            total: listReportHistory?.data?.total,
            defaultPageSize: params.limit,
            current: params.page || 1,
          }}
          handlePageChange={(pageChange) => {
            handlePageChange(pageChange, setParams);
          }}
          handlePageSizeChange={(current, size) => {
            handlePageSizeChange(size, setParams);
          }}
        />
      </div>
      {modalDetail.isOpen && (
        <ModalDetailReport
          modalDetail={modalDetail}
          setModalDetail={setModalDetail}
        />
      )}
    </div>
  );
};

export default withPermissionCheck(ListHistoryContainer, {
  view: "list-reports",
  detail: "detail-report",
  delete: "detail-report",
});
