import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import ButtonBase from "../../../components/Button";
import { useTranslation } from "react-i18next";

import { useGetReportInfoQuery } from "../../../store/Implement";
import { calculateAverage } from "../../../constants/ultil";
import ChartsComponent from "../../../components/Chart";

const ModalDetailReport = (props) => {
  const { t } = useTranslation();
  const { modalDetail, setModalDetail } = props;
  const [dataChart, setDataChart] = useState({});
  const [averageScore, setAverageScore] = useState();
  const [typeChart, setTypeChart] = useState("bar");

  const { data: infoReport } = useGetReportInfoQuery(
    modalDetail?.data?.id || "",
    {
      skip: !modalDetail?.data?.id,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (infoReport?.data) {
      let average = calculateAverage(infoReport?.data?.charts?.datasets?.data);
      setAverageScore(average);
    }
  }, [infoReport]);

  return (
    <Modal
      open={modalDetail.isOpen}
      footer={true}
      width={1200}
      onCancel={() => setModalDetail({ data: [], isOpen: false })}
    >
      <div className="px-6 py-4">
        <div className="bg-white p-6">
          <div className="flex justify-end mb-10">
            <ButtonBase
              title={t("report.exportResult")}
              className="!bg-white !text-[#0B0A0A] w-[155px] h-9"
              onClick={() => {
                const url = `/custom-report/${modalDetail?.data?.id}`;
                window.open(url, "_blank");
              }}
            />
          </div>
          <div className="flex justify-center">
            <div className="flex justify-between w-2/3 border-solid border-[1px] p-6">
              <div className="text-[13px]">
                <div className="flex">
                  <div className="font-bold">ID： </div>
                  <span>{modalDetail?.data?.person_id}</span>
                </div>
                <div className="flex my-2">
                  <div className="font-bold">{t("history.staffName")}： </div>
                  <span>{modalDetail?.data?.person_name}</span>
                </div>
                <div className="flex my-2">
                  <div className="font-bold">{t("company.companyName")}： </div>
                  <span>{modalDetail?.data?.company?.name}</span>
                </div>
              </div>
              <div>
                <div className="text-[19px] font-bold">
                  {t("history.averageScore")}
                </div>
                <div className="text-[36.36px] font-bold my-1">
                  {averageScore}%
                </div>
                <div className="text-[15px]">{`合計スコア: ${averageScore}/100`}</div>
              </div>
            </div>
          </div>
          <ChartsComponent
            setDataChart={setDataChart}
            dataReport={dataChart}
            infoReport={infoReport?.data}
            typeChart={typeChart}
            setTypeChart={setTypeChart}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalDetailReport;
