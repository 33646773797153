import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import { Spin, Row, Col, Form, Upload, message } from "antd";
import InputComponent from "../../../components/FormComponent/Input";
import ButtonBase from "../../../components/Button";
import HeaderContent from "../../../components/HeaderContent";
import {
  useCreateSiteConfigMutation,
  useGetListConfigQuery,
  useUploadFileMutation,
} from "../../../store/SiteConfig";

const SiteConfigContainer = () => {
  const { t } = useTranslation();
  const { data: listConfig, isLoading } = useGetListConfigQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [getUploadUrl] = useUploadFileMutation();
  const [updateSiteConfig] = useCreateSiteConfigMutation();

  const [imageFavicon, setImageFavicon] = useState();
  const [imageLogo, setImageLogo] = useState();
  const [loadingFavicon, setLoadingFavicon] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setLoadingLogo(true);
    setLoadingFavicon(true);
    const siteTitle = listConfig?.data?.find(
      (item) => item.key === "site_title" && item.language_code === "vi"
    );
    const siteFavicon = listConfig?.data?.find(
      (item) => item.key === "site_favicon" && item.language_code === "vi"
    );
    const siteLogo = listConfig?.data?.find(
      (item) => item.key === "site_logo" && item.language_code === "vi"
    );
    form.setFieldsValue({
      title: siteTitle?.value,
    });
    setImageFavicon(siteFavicon.value);
    setImageLogo(siteLogo.value);
  }, [listConfig, form]);

  const onSubmitData = async (values) => {
    const payload = {
      vi: {
        site_title: values?.title,
        site_favicon: imageFavicon,
        site_logo: imageLogo,
      },
    };
    try {
      await updateSiteConfig(payload).unwrap();
      message.success(t("setting.createSuccess"));
    } catch (error) {
      message.error(t("setting.errorNetwork"));
    }
  };

  const uploadFavicon = (
    <div>
      {loadingFavicon ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const uploadLogo = (
    <div>
      {loadingLogo ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handleUpload = async (file, key) => {
    if (key === "favicon") {
      setImageFavicon("");
      setLoadingFavicon(true);
    }
    if (key === "logo") {
      setImageLogo("");

      setLoadingLogo(true);
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "images");

    try {
      const response = await getUploadUrl(formData);
      if (key === "favicon") {
        setImageFavicon(response?.data?.data?.url);
        setLoadingFavicon(false);
      }
      if (key === "logo") {
        setImageLogo(response?.data?.data?.url);
        setLoadingLogo(false);
      }
      //   }
    } catch (error) {
      message.error(t("setting.errorNetwork"));
    }
  };

  return (
    <div>
      <div className="flex justify-between bg-white border-b border-[#e4e4e4]">
        <HeaderContent
          textCurrentPage={t("common.siteConfig")}
          className="ml-3"
        />
      </div>
      <div className="pt-12 flex justify-center">
        {isLoading ? (
          <Spin></Spin>
        ) : (
          <Form form={form} onFinish={onSubmitData}>
            <div className="bg-white w-[760px] h-full px-12 pt-12">
              <Row>
                <Col span={8}>{t("setting.title")}</Col>
                <Col span={16}>
                  <Form.Item name="title">
                    <InputComponent />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>{t("setting.favicon")}</Col>
                <Col span={16}>
                  <Form.Item name="favicon">
                    <Upload
                      name="avatar"
                      maxCount={1}
                      listType="picture-card"
                      showUploadList={false}
                      beforeUpload={(file) => {
                        handleUpload(file, "favicon");
                        return false;
                      }}
                    >
                      {imageFavicon ? (
                        <img
                          src={imageFavicon}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadFavicon
                      )}
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>{t("setting.logo")}</Col>
                <Col span={16}>
                  <Form.Item name="logo">
                    <Upload
                      name="avatar"
                      maxCount={1}
                      listType="picture-card"
                      showUploadList={false}
                      beforeUpload={(file) => {
                        handleUpload(file, "logo");
                        return false;
                      }}
                    >
                      {imageLogo ? (
                        <img
                          src={imageLogo}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadLogo
                      )}
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className="flex justify-end mt-6">
              <ButtonBase
                className="w-[108px] h-[36px]"
                title={t("common.submit")}
                htmlType="submit"
              />
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};
export default SiteConfigContainer;
