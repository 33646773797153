import React, { useEffect, useState, useRef } from "react";
import { message, Modal, Spin } from "antd";
import "grapesjs/dist/css/grapes.min.css";
import { GrapesjsReact } from "grapesjs-react";
import grapesjsNewsletter from "grapesjs-preset-newsletter";
import grapesjsForms from "grapesjs-plugin-forms";
import grapesjsExport from "grapesjs-plugin-export";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useGetReportInfoQuery,
  useUpdateHtmlReportMutation,
  useLazyExportReportQuery,
} from "../../store/Implement";
import ChartsComponent, { listChart } from "../../components/Chart";
import "./index.scss";
import SelectComponent from "../../components/FormComponent/Select";

const listBlockRemove = [
  "sect100",
  "sect50",
  "image",
  "sect30",
  "sect37",
  "grid-items",
  "list-items",
  "select",
  "checkbox",
  "radio",
  "form",
  "quote",
  "link",
  "link-block",
  "button",
  "input",
  "textarea",
];
const listPanelRemove = [
  "undo",
  "redo",
  "sw-visibility",
  "export-template",
  "gjs-open-import-template",
  "gjs-toggle-images",
];

const CustomReportContainer = () => {
  const { id } = useParams(); // Get id from URL
  const { t } = useTranslation();
  const [dataChart, setDataChart] = useState({});
  const [isOpenLoading, setIsLoading] = useState(false);
  const { data: infoReport, refetch } = useGetReportInfoQuery(id || "", {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });
  const [forceUpdate, setForceUpdate] = useState(0);
  const [jsonData, setJsonData] = useState([]);
  const [dataTable, setDataTable] = useState([]);

  const [dataUrl, setDataUrl] = useState("");
  const canvasRef = useRef(null);
  const [typeChart, setTypeChart] = useState("bar");

  const [updateHtmlReport] = useUpdateHtmlReportMutation();

  const saveContent = async () => {
    setIsLoading(true);
    const html = window.editor.getHtml(); // Lấy HTML từ GrapesJS
    const css = window.editor.getCss(); // Lấy CSS từ GrapesJS

    const dataToSend = {
      html,
      css,
    };
    try {
      await updateHtmlReport({ id, data: dataToSend });
      localStorage.removeItem("gjsProject");
      message.success(t("setting.updateSuccess"));
      refetch();
    } catch (error) {
      message.error(t("setting.errorNetwork"));
    }
    setIsLoading(false);
  };
  const [triggerExportPdf] = useLazyExportReportQuery();
  const downloadContent = async () => {
    setIsLoading(true);
    try {
      const { blob, fileName } = await triggerExportPdf(id).unwrap();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      message.success(t("setting.downloadSuccess"));
    } catch (error) {
      message.error(t("setting.errorNetwork"));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (infoReport?.data) {
      const parsedJson = infoReport.data.json
        ? JSON.parse(infoReport.data.json)
        : [];
      setJsonData(parsedJson);
      setDataTable(infoReport.data?.charts);
    }
    setForceUpdate((prev) => prev + 1);
  }, [infoReport]);

  const generateHtmlFromData = () => {
    return jsonData
      .map(
        (item) =>
          `<div style="padding: 10px 0">
          <h2>${item.name || "N/A"}</h2>
          <p>${item.score || "N/A"}  点</p>
          <p>${item.description || "No description available"}</p>
        </div>`
      )
      .join("");
  };
  const createCustomTable = (charts) => {
    const { datasets, labels } = charts;

    let tableHtml = `
    <div style="margin-top: 20px; font-weight: 700">${t(
      "report.allCompanyReport"
    )}</div>
    <table style="width: 100%; border-collapse: collapse; text-align: left; border: 1px solid #ddd; margin: 20px 0px 50px 0px">
      <thead>
        <tr>
          <th style="padding: 10px; border: 1px solid #ddd;"></th>
          <th style="padding: 10px; border: 1px solid #ddd; text-align: center;">${
            datasets?.label
          }</th>
        </tr>
      </thead>
      <tbody>`;

    labels?.forEach((label, index) => {
      tableHtml += `
      <tr>
        <td style="padding: 10px; border: 1px solid #ddd;">${label}</td>
        <td style="padding: 10px; border: 1px solid #ddd; text-align: center;">${datasets.data[index]} 点</td>
      </tr>
    `;
    });

    tableHtml += `</tbody></table>`;

    return tableHtml;
  };

  useEffect(() => {
    const handleTabClose = (event) => {
      localStorage.removeItem("gjsProject"); // Xóa gjsProject
    };

    window.addEventListener("unload", handleTabClose);

    return () => {
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  useEffect(() => {
    if (canvasRef.current) {
      const imgBase64 = canvasRef.current.toBase64Image();
      const iframe = document.querySelector("#grapesjs-react iframe");
      if (iframe) {
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow.document;
        const imgChart = iframeDoc.getElementById("chart-image");
        if (imgChart) {
          imgChart.src = imgBase64;
        }
      }
      setDataUrl(imgBase64);
    }
  }, [dataChart, dataUrl]);

  return (
    <>
      <div className="flex justify-around my-4 text-xl font-bold">
        <div>{t("history.ScoringByItem")}</div>
        <SelectComponent
          className="w-[150px]"
          options={listChart}
          value={typeChart}
          onChange={(e) => {
            setTypeChart(e);
          }}
        />
      </div>
      <GrapesjsReact
        key={forceUpdate}
        id="grapesjs-react"
        plugins={[
          grapesjsNewsletter,
          grapesjsForms,
          grapesjsExport,
          (editor) => {
            const panelManager = editor.Panels;

            listPanelRemove.forEach((item) => {
              panelManager.removeButton("options", item);
            });
            // Thêm nút "Save" vào thanh công cụ
            panelManager.addButton("options", {
              id: "save-button",
              className: "fa fa-save",
              command: "save-project", // Command liên kết
              attributes: { title: "Save Report" },
            });

            // Thêm command "save-project" để xử lý hành động lưu
            editor.Commands.add("save-project", {
              run: () => {
                saveContent();
              },
            });
            panelManager.addButton("options", {
              id: "download-button",
              className: "fa fa-download",
              command: "download-report", // Command liên kết
              attributes: { title: "Download Report" },
            });

            // Thêm command "download-report" để xử lý hành động tải về
            editor.Commands.add("download-report", {
              run: () => {
                downloadContent();
              },
            });
            const blockManager = editor.BlockManager;

            listBlockRemove.forEach((item) => {
              blockManager.remove(item);
            });
          },
        ]}
        onInit={(editor) => {
          window.editor = editor;
          const htmlContent = generateHtmlFromData();
          const tableHtml = createCustomTable(dataTable);
          editor.addComponents(tableHtml);
          editor.addComponents(
            `<img src="${dataUrl}" id="chart-image" style="width: 100%; height: 50%" />`
          );

          editor.addComponents(htmlContent);

          // Set css for the wrapper
          const wrapper = editor.getWrapper();
          wrapper.addStyle({
            "max-width": "800px",
            margin: "0 auto",
            "font-family": "'Noto Sans JP', sans-serif",
          });
        }}
      />
      <div className="bg-white">
        <ChartsComponent
          setDataChart={setDataChart}
          dataReport={dataChart}
          infoReport={infoReport?.data}
          typeChart={typeChart}
          setTypeChart={setTypeChart}
          canvasRef={canvasRef}
          hiddenChart={true}
        />
      </div>
      <Modal
        open={isOpenLoading}
        footer={false}
        closeIcon={false}
        width={500}
        className="custom-modal"
      >
        <div className="flex justify-center mt-4">
          <Spin></Spin>
        </div>
      </Modal>
    </>
  );
};

export default CustomReportContainer;
