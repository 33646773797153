import React from "react";
import "./index.scss";
import { Form, message } from "antd";
import InputComponent from "../../../components/FormComponent/Input";
import ButtonBase from "../../../components/Button";
import { useValidationRules } from "../../../constants/rules";
import { useLoginMutation } from "../../../store/Auth/Login";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import InputPasswordComponent from "../../../components/FormComponent/InputPassword";
import { useDispatch } from "react-redux";
import { storeSetAuth } from "../../../store/authReducer";

const LoginContainer = ({ logoConfig }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { MailRules, PasswordRules } = useValidationRules();
  const [login, { isLoading }] = useLoginMutation();

  const onSubmitLogin = async (values) => {
    try {
      const userData = await login(values).unwrap();
      if (userData.data.token) {
        Cookies.set("accessToken", userData.data.token, { expires: 1 });
        dispatch(storeSetAuth(userData.data));
      }
    } catch {
      message.error(t("auth.errLogin"));
    }
  };
  return (
    <div className="bg-[#999999] min-h-screen">
      <div className="pl-10 py-[14px]">
        <img src={logoConfig?.value} width={215} alt="logo" />
      </div>
      <div className="flex justify-around">
        <div className="text-[#999999]">abc</div>
        <div className="bg-white rounded w-[375px] h-full mt-10 p-6">
          <div className="text-xl font-bold text-[#ff7421] mb-[18px]">
            {t("auth.welcomBrandBook")}
          </div>
          <div className="header-modal-login"></div>
          <Form className="mt-6" onFinish={onSubmitLogin}>
            <Form.Item name="email" rules={MailRules}>
              <InputComponent
                title={t("auth.email")}
                placeholder={t("auth.pleaseInputEmail")}
                required
              />
            </Form.Item>
            <Form.Item name="password" rules={PasswordRules}>
              <InputPasswordComponent
                title={t("account.password")}
                placeholder={t("auth.pleaseInputPassword")}
                required
              />
            </Form.Item>
            <ButtonBase
              title={t("auth.login")}
              htmlType="submit"
              className="w-[327px] h-11 mt-4"
              loading={isLoading}
            />
          </Form>
          <div className="text-[11px] mt-2 mb-6">
            <span>{t("auth.note1")}</span>
            <a href="/" className="text-[#ff7421]">
              {t("auth.termOfUse")}
            </a>
            <span>{t("auth.note2")}</span>
          </div>
          <div
            className="text-[13px] text-[#ff7421] cursor-pointer"
            onClick={() => navigate("/auth/forgot-password")}
          >
            {t("auth.forgotPassword")}
          </div>
          <div className="header-modal-login mt-6"></div>
        </div>
      </div>
    </div>
  );
};

export default LoginContainer;
