import { Button } from "antd";
import React from "react";

const ButtonBase = (props) => {
  const { title, className, onClick, ...otherProps } = props;
  return (
    <Button
      className={`${className} button-base bg-[#ff7421] text-white px-7 py-[10px] hover:!border-[#ff7421] hover:!text-[#ff7421] disabled:bg-[#f4f4f4] disabled:!border-[#d3d3d3] disabled:!text-[#a9a9a9]`}
      {...otherProps}
      onClick={onClick}
    >
      <div>{title}</div>
    </Button>
  );
};
export default ButtonBase;
