import { Input } from "antd";
import React from "react";

const InputPasswordComponent = (props) => {
  const { className, title, required, ...otherProps } = props;
  return (
    <div>
      <div>
        {title}
        {required && <span className="text-[#ff0000]">*</span>}
      </div>
      <Input.Password
        className={`input-password ${className} h-10 hover:!border-[#ff7421] focus:!border-[#ff7421]`}
        {...otherProps}
      />
    </div>
  );
};
export default InputPasswordComponent;
