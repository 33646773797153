import React from "react";
import { Table } from "antd";
import "./index.scss";

const TableBaseComponent = (props) => {
  const {
    columns,
    dataSource,
    paginationOptions,
    handlePageChange,
    handlePageSizeChange,
    ...otherProps
  } = props;

  return (
    <Table
      className="table-component"
      columns={columns}
      dataSource={dataSource}
      {...otherProps}
      pagination={
        paginationOptions
          ? {
              total: paginationOptions?.total,
              defaultPageSize: paginationOptions?.defaultPageSize || 10,
              current: paginationOptions?.current || 1,
              onChange: handlePageChange,
              onShowSizeChange: handlePageSizeChange,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],
            }
          : false
      }
    />
  );
};
export default TableBaseComponent;
