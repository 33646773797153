import { Select } from "antd";
import React from "react";
import "./index.scss";
const SelectComponent = (props) => {
  const { className, title, required, ...otherProps } = props;
  return (
    <div>
      <div>
        {title}
        {required && <span className="text-[#ff0000]">*</span>}
      </div>
      <Select
        className={`${className} h-full hover:!border-[#ff7421] focus:!border-[#ff7421] select-component`}
        {...otherProps}
      />
    </div>
  );
};
export default SelectComponent;
