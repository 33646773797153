import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithErrorHandling from '../../api/apiConfig';

export const apiEmployeeSlice = createApi({
  reducerPath: 'employeeApi', // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['Employee'], // Tag để invalidate cache cho các thao tác như create, update, delete
   endpoints: (builder) => ({

    getListEmployees: builder.query({
      query: ({id, params}) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/company/${id}/persons`,
        params: { ...params }, // Gửi tham số lên API
      }),
      providesTags: (result) =>
        result
          ? [...result.data.data.map(({ id }) => ({ type: 'Employee', id })), { type: 'Employee', id: 'LIST' }]
          : [{ type: 'Employee', id: 'LIST' }],
    }),

    getEmployeeById: builder.query({
      query: (id) => `/${process.env.REACT_APP_API_VERSION}/person/${id}`,
      providesTags: (result, error, id) => [{ type: 'Employee', id }],
    }),

    createEmployee: builder.mutation({
      query: (newEmployee) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/person`,
        method: 'POST',
        body: newEmployee,
      }),
      invalidatesTags:  (result, error) => {
        return result ? [{ type: 'Employee', id: 'LIST' }] : [] // Invalidate cache to refresh data after create
      }
    }),

    updateEmployee: builder.mutation({
      query: ({ id, ...updatedEmployee }) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/person/${id}/update`,
        method: 'POST', // Có thể dùng PATCH nếu muốn cập nhật một phần dữ liệu
        body: updatedEmployee.data,
      }),
      invalidatesTags: (result, error, { id }) => result?  [{ type: 'Employee', id }] : [],
    }),

    deleteEmployee: builder.mutation({
      query: (id) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/person/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) => result ?  [{ type: 'Employee', id: 'LIST' }] : [],
    }),

    getUploadUrl: builder.mutation({
      query: (fileName) => { console.log(fileName);
       return ({
        url: `/${process.env.REACT_APP_API_VERSION}/file/get-presigned-url`,
        method: 'POST',
        body:  fileName ,
      })},
    }),
  }),
});

export const {
  useGetListEmployeesQuery,
  useGetEmployeeByIdQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
  useGetUploadUrlMutation,
} = apiEmployeeSlice;