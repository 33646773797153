import React, { useEffect, useState } from "react";
import HeaderContent from "../../../../components/HeaderContent";
import { useNavigate } from "react-router-dom";
import {
  useGetListRoleQuery,
  useGetUserInfoQuery,
  useUpdateRoleMutation,
} from "../../../../store/Settings/Role";
import "./index.scss";
import { Checkbox, message } from "antd";
import ButtonBase from "../../../../components/Button";
import { useTranslation } from "react-i18next";

const RoleContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const permissionId = localStorage.getItem("permissionId");
  const [updateListRole, setUpdateListRole] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updateRole] = useUpdateRoleMutation();
  const { data: userInfo } = useGetUserInfoQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: listRoles } = useGetListRoleQuery(permissionId, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });

  useEffect(() => {
    const hasAdmin = userInfo?.data?.role === "administrator";
    if (!hasAdmin) {
      navigate("/403");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (listRoles?.data?.permissions) {
      const dataRoles = Object.values(listRoles.data.permissions)
        .map((category) =>
          category.filter((item) => item.active).map((item) => item.name)
        )
        .flat();
      setUpdateListRole(dataRoles);
    }
  }, [listRoles]);

  const onChange = (e, value) => {
    let data = [...updateListRole];
    if (e.target.checked) {
      const newData = [...data, value.name];
      setUpdateListRole(newData);
    } else {
      const filterData = data.filter((item) => item !== value.name);
      setUpdateListRole(filterData);
    }
  };

  const onUpdateRole = async (data) => {
    setIsLoading(true);
    let payload = {
      name: listRoles?.data?.role?.name,
      permissions: data,
    };
    try {
      await updateRole({
        id: permissionId,
        data: payload,
      }).unwrap();
      message.success(t("setting.updateSuccess"));
    } catch (error) {
      message.error(t("setting.errorNetwork"));
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div className="flex justify-between bg-white border-b border-[#e4e4e4]">
        <HeaderContent
          textCurrentPage={t("setting.detailRole")}
          textPreviousPage={t("setting.permission")}
          actionBack={() => {
            localStorage.removeItem("permissionId");
            navigate("/setting/permission");
          }}
        />
        <div className="flex items-center pr-5">
          <ButtonBase
            className="w-[108px] h-[36px]"
            title={t("setting.newCreate")}
            onClick={() => onUpdateRole(updateListRole)}
            disabled={isLoading}
            loading={isLoading}
          />
        </div>
      </div>
      <div className="bg-white p-4">
        {listRoles?.data?.permissions && (
          <table className="permission-table rounded-md">
            <tbody>
              {Object.keys(listRoles.data.permissions).map((category) => (
                <tr key={category}>
                  <td className="category-cell">{category}</td>
                  <td className="permissions-cell">
                    <div className="permissions-wrapper">
                      {listRoles.data.permissions[category].map((item) => (
                        <div key={item.id} className="permission-item">
                          <Checkbox
                            onChange={(e) => onChange(e, item)}
                            checked={updateListRole.includes(item.name)}
                          >
                            {item.name}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default RoleContainer;
